var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"preview-popup-main-container"},[_c('b-modal',{key:_vm.fileDetails[_vm.fileDetails.length > 1 ? 0 : _vm.slideIndex].originalFileName
        ? _vm.fileDetails[_vm.fileDetails.length > 1 ? 0 : _vm.slideIndex]
            .originalFileName
        : _vm.fileDetails[_vm.fileDetails.length > 1 ? 0 : _vm.slideIndex].name,attrs:{"id":'preview-modal-center-' + _vm.keyName,"centered":"","title":_vm.fileDetails[_vm.slideIndex].originalFileName
        ? _vm.fileDetails[_vm.slideIndex].originalFileName
        : _vm.fileDetails[_vm.slideIndex].name,"hide-footer":""},on:{"hide":function($event){return _vm.hide($event)}}},[(
        ((_vm.fileDetails[_vm.slideIndex].isImage === true ||
          _vm.fileDetails[_vm.slideIndex].isDocument === true) &&
          _vm.fileDetails[_vm.slideIndex].uploadedFileName) ||
        _vm.fileDetails[_vm.slideIndex].type === 'docx' ||
        _vm.fileDetails[_vm.slideIndex].type === 'doc'
      )?_c('div',{staticClass:"download-icon"},[_c('b-icon',{attrs:{"icon":"download"},on:{"click":function($event){return _vm.downloadImageFromServer(
            _vm.fileDetails[_vm.slideIndex].uploadedFileName
              ? `${_vm.S3Path}/${_vm.fileDetails[_vm.slideIndex].uploadedFileName}`
              : _vm.fileDetails[_vm.slideIndex].fileName
              ? `${_vm.S3Path}/${_vm.fileDetails[_vm.slideIndex].fileName}`
              : `${_vm.fileDetails[_vm.slideIndex]?.url}`,
            _vm.fileDetails[_vm.slideIndex].originalFileName
              ? _vm.fileDetails[_vm.slideIndex].originalFileName
              : _vm.fileDetails[_vm.slideIndex].name
          )}}})],1):_vm._e(),(_vm.fileDetails.length > 1)?_c('div',{staticClass:"carousel-control-prev popup-prev",class:_vm.slideIndex === 0 ? 'disable' : ''},[_c('span',{staticClass:"carousel-control-prev-icon",on:{"click":function($event){return _vm.prev()}}})]):_vm._e(),(_vm.fileDetails.length > 1)?_c('div',{staticClass:"carousel-control-next popup-next",class:_vm.slideIndex === _vm.totalLength - 1 ? 'disable' : '',on:{"click":function($event){return _vm.next()}}},[_c('span',{staticClass:"carousel-control-next-icon"})]):_vm._e(),_c('div',{staticClass:"preview-container",class:_vm.fileDetails[_vm.slideIndex].isImage ? 'image' : ''},[(_vm.fileDetails[_vm.slideIndex].isDocument === true)?_c('iframe',{staticClass:"document-container",attrs:{"src":_vm.fileDetails[_vm.slideIndex].uploadedFileName
            ? `${_vm.S3Path}/${_vm.fileDetails[_vm.slideIndex].uploadedFileName}#zoom=100%`
            : _vm.fileDetails[_vm.slideIndex].fileName
            ? `${_vm.S3Path}/${_vm.fileDetails[_vm.slideIndex].fileName}#zoom=100%`
            : `${_vm.fileDetails[_vm.slideIndex]?.url}#zoom=100%`,"type":`application/${_vm.fileDetails[_vm.slideIndex].type}`},on:{"load":_vm.loadData}}):_vm._e(),(_vm.fileDetails[_vm.slideIndex].isUnknown === true)?_c('div',{staticClass:"preview-message"},[_c('span',{staticClass:"error-message"},[_vm._v(" Preview is not available, please download the file and view by clicking the download icon above. ")])]):_vm._e(),(_vm.fileDetails[_vm.slideIndex].isImage === true)?_c('img',{staticClass:"image-container",attrs:{"src":_vm.fileDetails[_vm.slideIndex].uploadedFileName
            ? `${_vm.S3Path}/${_vm.fileDetails[_vm.slideIndex].uploadedFileName}`
            : _vm.fileDetails[_vm.slideIndex].fileName
            ? `${_vm.S3Path}/${_vm.fileDetails[_vm.slideIndex].fileName}`
            : `${_vm.fileDetails[_vm.slideIndex]?.url}`},on:{"load":_vm.loadData}}):_vm._e()]),(_vm.isLoading)?_c('AppLoaderComponent',{attrs:{"setLoadingCenterDiv":true,"type":'large'}}):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
import { Component, Prop, Vue } from "vue-property-decorator";
import AppLoaderComponent from "../app-loader/app-loader.component.vue";
import { appConfigService } from "@/providers/services/app/app-config";
import axios from "axios";
// eslint-disable-next-line @typescript-eslint/no-var-requires
// const docxParser = require("docx-preview");
@Component({
  components: {
    AppLoaderComponent,
  },
})
export default class PreviewPopupComponent extends Vue {
  @Prop()
  public fileDetails!: any[];
  @Prop()
  public keyName!: string;
  @Prop()
  public typeOfView!: string;
  @Prop()
  public isPeerPreview!: any;

  public S3Path = "";
  public imgType = ["jpg", "png", "jpeg", "tiff"];
  public docType = ["pdf"];
  public isLoading = false;
  public slideIndex = 0;
  public totalLength = 0;
  hasUnknownFile: any;

  public mounted() {
    let getFileType = "";
    this.$nextTick().then(() => {
      this.totalLength = this.fileDetails.length;
      this.S3Path =
        this.typeOfView === "certificate" || this.isPeerPreview
          ? appConfigService.getS3CertificatesFilesPathUrl()
          : appConfigService.getS3FilesPathUrl();
      this.fileDetails.length &&
        this.fileDetails.map((file: any) => {
          getFileType =
            file && file.uploadedFileName
              ? file.uploadedFileName.split(".")[1]
              : file.fileName
              ? file.fileName.split(".")[1]
              : file.type.split("/")[1];
          file.type = getFileType;
          if (this.docType.includes(getFileType)) {
            file.isDocument = true;
            file.isImage = false;
          } else if (this.imgType.includes(getFileType)) {
            file.isDocument = false;
            file.isImage = true;
          } else {
            file.isDocument = false;
            file.isImage = false;
            file.isUnknown = true;
            this.hasUnknownFile = true;
          }
        });
      this.isLoading = true;
      if (this.hasUnknownFile) {
        this.isLoading = false;
      }
      this.$bvModal.show(`preview-modal-center-` + this.keyName);
    });
  }
  public hide(event: any) {
    if (event) {
      this.$emit("hidePreviewPopup", true);
    }
  }
  public async downloadImageFromServer(data: any, getImageName: any) {
    try {
      const response = await axios({
        url: data,
        method: "GET",
        responseType: "blob",
      });
      const blob = new Blob([response.data]);
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = getImageName;
      link.click();
    } catch (error) {
      console.error(error);
    }
  }
  public loadData(event: any) {
    this.isLoading = false;
  }

  public prev() {
    if (this.slideIndex <= this.totalLength - 1 && this.slideIndex != 0) {
      this.isLoading = true;
      this.slideIndex--;
    }
  }
  public next() {
    if (this.slideIndex < this.totalLength - 1) {
      this.isLoading = true;
      this.slideIndex++;
    }
  }
  // public loadDocx = async (fileData: any, S3Path: any) => {
  //   const docxUrl = `${S3Path}/${fileData.uploadedFileName}`;
  //   try {
  //     const response = await fetch(docxUrl);
  //     if (!response.ok)
  //       throw new Error(`Failed to fetch document. Status: ${response.status}`);
  //     const arrayBuffer = await response.arrayBuffer();
  //     const docxContainer = document.getElementById("docxContainer");
  //     if (!docxContainer) {
  //       throw new Error("Container element not found.");
  //     }
  //     const options = {
  //       className: "docx",
  //       inWrapper: true,
  //       experimental: true,
  //     };
  //     await docxParser.renderAsync(
  //       arrayBuffer,
  //       docxContainer,
  //       undefined,
  //       options
  //     );
  //     this.loadData(null);
  //   } catch (error: any) {
  //     alert(`Unable to load document. Error: ${error.message}`);
  //   }
  // };

  public closePopup() {
    this.$bvModal.hide(`preview-modal-center-` + this.keyName);
    this.slideIndex = 0;
  }
}
